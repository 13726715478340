.container {
  background: linear-gradient(rgba(85, 85, 241, 0.15) 40%, transparent),
    url('/src/assets/background.jpg') center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  
  
  .content {
    margin: 5.5rem auto;
    text-align: center;
  }

  .thankyou {
    flex: 1;
    width: 95%;
    height: 90%;
  }
  