

* {
  font-family: 'Helvetica', 'Helvetica Neue', 'Arial', -apple-system, BlinkMacSystemFont;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #c7e1f5  ;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
