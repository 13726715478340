.container {
  background-color:linear-gradient(rgba(85, 85, 241, 0.15) 40%, transparent),
  url('/src/assets/footer.png');
display: flex;
flex-direction: column;
align-items: center;
width: 100vw;
height: 100vh;
  }
  
  .footerBox {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .footer{
    /* flex: 1; */
    width: 100%;
    height: 30%;
    resize: both;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  
  
  .content {
    margin: 3rem auto;
    text-align: center;
  }
  
  .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 2.5rem;
    color: #fff;
    margin: 0;
    padding: 1rem 0;
  }
  
  .subtitle {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight:4300;
    font-size: 1.5rem;
    color: #fff;
    margin: 0;
    padding: 1rem 0;
  }
  
  .subtitle code {
    color: #ffa221;
  }
  