.container {
  background: linear-gradient(rgba(85, 85, 241, 0.15) 40%, transparent),
    url('/src/assets/background.jpg') center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.logo {
  width: clamp(300px, 30%, 500px);
  margin: 5rem 0 1rem;
}

.content {
  margin: 2rem auto;
  text-align: center;
}

.title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  color: #fff;
  margin: 0;
  /* padding: 1rem 0; */
}

.subtitle {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight:4300;
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
  padding: 1rem 0;
}

.subtitle code {
  color: #ffa221;
}

.welcome {
  flex: 1;
  width: 58%;
  height: 15%;
  /* padding-bottom: 0px; */
}

.register {
  flex: 1;
  width: 30%;
  height: 10%;
}