:root {
  --font: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin-top: 60px;
}

.logo {
  width: clamp(300px, 30%, 500px);
  margin: 5rem 0 1rem;
}

.content {
  margin: 2rem auto;
  text-align: left;
  padding: 0 50px 0 50px;
}

.title {
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  margin: 0;
  padding: 1rem 0;
}

.subtitle {
  font-style: normal;
  font-weight: 4300;
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
  padding: 1rem 0;
}

.subtitle code {
  color: #ffa221;
}

.text {
  width: 475px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
}

.text > * {
  font-family: HandelGothic, sans-serif;
  color: white;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-left: 0.25ch;
}

.dynamic {
  color: #ffa221;
  margin-left: 0;
}

.input {
  margin-right: 1.5rem;
  margin-left: 1rem;
  border: 1.5px solid #c50022;
  border-radius: 10px;
  height: 2.3rem;
  width: 15rem;
}

.inputDropdown {
  margin-right: 1.5rem;
  border: 1.5px solid #c50022;
  border-radius: 10px;
  margin-left: 1rem;
  height: 2.3rem;
}

.field {
  margin-right: 1.5rem;
  margin-left: 1rem;
  border: 1.5px solid #004a96;
  border-radius: 10px;
  height: 2.3rem;
  width: 15rem;
}

.fieldDropdown {
  margin-right: 1.5rem;
  margin-left: 1rem;
  border: 1.5px solid #004a96;
  border-radius: 10px;
  height: 2.3rem;
}

.gridContainer {
  text-align: left;
  padding: 12px 0;
  display: grid;
  row-gap: 40px;
  /* grid-template-rows: auto; */
  grid-template-areas:
    'salutation firstname firstname lastname lastname'
    'jobTitle jobTitle company salesRep salesRep'
    'phone phone email email email'
    'info info info info info'
    'consentemail consentemail consentemail consentemail consentemail';
}

/* .username {
  display: flex;
  justify-content: flex-start;
} */

.salutation {
  grid-area: salutation;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.firstname {
  grid-area: firstname;
  justify-self: center;
  display: flex;
  align-items: center;
  font-size: var(--font);
  margin-left: 30px;
}

.firstnameInput {
  width: 350px;
}

.lastname {
  grid-area: lastname;
  justify-self: center;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.lastnameInput {
  width: 350px;
}

/* .details {
  display: flex;
  justify-content: flex-start;
} */

.jobTitle {
  grid-area: jobTitle;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.jobTitleInput {
  margin-left: 25px;
  width: 260px;
}

.company {
  grid-area: company;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.salesRep {
  grid-area: salesRep;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.phone {
  grid-area: phone;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.phoneInput {
  margin-left: 37px;
}

.email {
  grid-area: email;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.emailInput {
  margin-left: 41px;
  width: 450px;
}

.consentsinfo {
  grid-area: info;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.consentsemail {
  grid-area: consentemail;
  display: flex;
  align-items: center;
  font-size: var(--font);
}

.break {
  margin-top: 18px;
}

.registerButton {
  background-color: #f18a00;
  border: 1px solid #004a96;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.registerButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 3rem;
}

.registerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fieldRadio {
  border-color: #004a96;
  margin-left: 15px;
}

.inputRadio {
  border-color: rgb(197, 0, 34);
  margin-left: 15px;
  transform: scale(1.3);

}

.errorMessage {
  color: rgb(197, 0, 34);
  text-align: center;
  width: 200px;
  position: absolute;
  margin: 5px 5px 0px 15px;
}

.errorForm {
  color: rgb(197, 0, 34);
  text-align: center;
  width: 200px;
}

::placeholder {
  text-align: center;
}

/* or, for legacy browsers */

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
