.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.logo {
  width: clamp(300px, 30%, 500px);
  margin: 5rem 0 1rem;
}

.content {
  margin: 3rem auto;
  text-align: center;
}

.title {
  /* font-family: 'Roboto Condensed', sans-serif; */
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  color: #fff;
  margin: 0;
  padding: 1rem 0;
}

.subtitle {
  /* font-family: 'Roboto Condensed', sans-serif; */
  font-style: normal;
  font-weight:4300;
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
  padding: 1rem 0;
}

.subtitle code {
  color: #ffa221;
}
  